import Mak from './images/Mak.png';
import './App.css';
function App() {
  return (
    <div className="App">
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"></link>
      <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" type="text/css"></link>
      <link href="https://fonts.googleapis.com/css?family=Lato" rel="stylesheet" type="text/css"></link>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
      <body id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
        <nav class="navbar navbar-default navbar-fixed-top">
            <a class="navbar-brand" href="#myPage"> <img src={Mak} alt="mak tiling logo" ></img></a>
              <ul class="nav navbar-nav navbar-right">
                <li><a href="#portfolio">PORTFOLIO</a></li>
                <li><a href="#about">ABOUT</a></li>
                <li><a href="#services">SERVICES</a></li>
                <li><a href="#contact">CONTACT</a></li>
              </ul>
        </nav>

        <div class="jumbotron text-center">
          <h1>MAK Tiling</h1>
          <h3>Services and Specialties</h3>
          <p>Specialize in Wall & Floor Tiling (Including; Grouting, Porcelain Tiles, Ceramic Tiles,
              Stone Tile Waterproofing, Caulking, Screeding Floors, Showerbases)</p>
        </div>

        <div id="about" class="container-fluid text-center">
          <div class="row">
            <div class="col-sm-8">
              <h2>Business Info</h2><br></br>
              <h4>Business established in 2010:</h4><br></br>
              <p>We are based in the South Eastern suburbs.</p>
              <p>We cover a wide area of operations .</p>
              <p>Our professionals are qualified tilers.</p>
              <br></br><button class="btn btn-default btn-lg">Get in Touch</button>
            </div>
            <div class="col-sm-4">
              <span class="glyphicon glyphicon-signal logo slide"></span>
            </div>
          </div>
        </div>

        <div class="container-fluid text-center bg-grey">
          <div class="row">
            <div class="col-sm-4">
              <span class="glyphicon glyphicon-globe logo slide"></span>
            </div>
            <div class="col-sm-8">
              <h2>MISSION STATEMENT:</h2><br></br>
                <h4>We are qualified tilers, passionate about our trade.</h4>
                <h4>We provide the highest quality work with premium materials used to ensure longevity and peace of mind when carrying out our works.</h4>
                <h4>We make sure to leave our place of work in a clean and tidy manner.</h4>
            <h4>Ultimately what sets us apart from other companies is our competitive pricing for the highest quality work provided, no cutting corners.</h4><br></br>
            </div>
          </div>
        </div>


        <div id="services" class="container-fluid text-center">
          <h2>SERVICES</h2>
          <h4>What we offer</h4>
          <div class="row slide">
            <div class="col-sm-4">
              <span class="glyphicon glyphicon-lock logo-small"></span>
              <h4>Fully Insured</h4>
              <p>Free quotes</p>
            </div>
            <div class="col-sm-4">
                <span class="glyphicon glyphicon-certificate logo-small"></span>
                <h4>CERTIFIED</h4>
                <p>Over eight years worth of experience</p>
            </div>
            <div class="col-sm-4">
                <span class="glyphicon glyphicon-wrench logo-small"></span>
                <h4 style={{color:"#303030"}}>JOB DONE</h4>
                <p>Highest quality materials</p>
                </div>
            </div>
          </div>


        <div id="contact" class="container-fluid text-center bg-grey">
          <h2 class="text-center">CONTACT</h2>
          <div class="row">
            <div class="col-sm-5">
              <p>Contact us and we'll get back to you as soon as possible.</p>
              <p><span class="glyphicon glyphicon-map-marker"></span> Melbourne, Australia</p>
              <p><span class="glyphicon glyphicon-phone"></span> +61 0452 647 544</p>
              <p><span class="glyphicon glyphicon-envelope"></span> marildo@maktiling.com</p>
              <p><span class="glyphicon glyphicon-envelope"></span> accounts@maktiling.com</p>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-4 form-group">
                  <input class="form-control" id="name" name="name" placeholder="Name" type="text" required></input>
                </div>
                <div class="col-sm-4 form-group">
                  <input class="form-control" id="email" name="email" placeholder="Email" type="email" required></input>
                </div>
              </div>
              <textarea class="form-control" id="comments" name="comments" placeholder="Comment" rows="5"></textarea><br></br>
              <div class="row">
                <div class="col-sm-12 form-group">
                  <button class="btn btn-default pull-right" type="submit">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer class="container-fluid text-center">
          <a href="#myPage" title="To Top">
            <span class="glyphicon glyphicon-chevron-up"></span>
            <p>Trading hours: Mon - Sat 7:30 a.m - 5:30 p.m.</p>
            <p>Office hours: Mon -  Fri 8:30 a.m - 5:30 p.m.</p>
          </a>
        </footer>
      </body>
    </div>
  );
}

export default App;
